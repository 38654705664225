<template>
  <div class="container-fluid my-2 mrl-2">

    <div id="mapid"></div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      buildings: [],
      map: {},
    };
  },
  methods: {
    logLangLat: function (e) {
      alert("You clicked the map at " + e.latlng);
    },
    navigate: function (buildingId) {
      this.$router.push({ name: "Building", params: { buildingId } });
    },
    createMap: function () {
      /* eslint-disable  */
      let map = L.map("mapid", {
        crs: L.CRS.Simple,
        maxZoom: 1,
        minZoom: -2,
      });
      let bounds = [
        [0, 0],
        [3684, 4000],
      ];
      let image = L.imageOverlay("/images/main/mappl.jpg", bounds).addTo(map);
      map.setView([1842, 2000], -2);
      this.map = map;
      return map;
    },
    getTooltipTemplate: function (title) {
      return `<span class='map-tooltip'>${title}</span>`;
    },
    createMarkers: function (map) {
      /* eslint-disable  */
      this.buildings
        .filter((buildng) => buildng.latitude && buildng.longitude)
        .forEach((building) => {
          let point = L.latLng([building.latitude, building.longitude]);
          let marker = L.marker(point)
            .addTo(this.map)
            .bindTooltip(this.getTooltipTemplate(building.title));

          marker.on("click", () => this.navigate(building.slug));
        });
    },
  },
  created() {},
  mounted() {
    this.createMap();
    this.$api.getBuildings().then((data) => {
      this.buildings = data;
      this.createMarkers();
    });

    //  map.on("click", this.logLangLat);
  },
};
</script>
<style >
#mapid {
  height: 600px;
  width: auto;
}
.leaflet-control-attribution.leaflet-control {
  display: none;
}
.leaflet-popup-close-button {
  display: none;
}
.map-tooltip {
  font-size: 16px;
}
</style>