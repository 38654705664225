<template>
  <div class="picture-component">
    <button type="button" class="picture-close" @click="navigateToBuilding()">
      <span>&times;</span>
    </button>
    <div class="picture">
      <img :src="path" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Picture",
  data() {
    return {
      path: "",
    };
  },
  methods:{
    navigateToBuilding: function () {
      let buildingId = this.$route.params.slug
      this.$router.push({ name: "Building", params: {buildingId }  });
    },
  },
  created() {
      this.path =
      this.$settings.galleryPath +
      "/" +
      this.$route.params.file + '.' + this.$route.params.ext;
  },
  beforeRouteUpdate(to, from, next) {
    this.path =
      this.$settings.galleryPath +
      "/" +
      this.$route.params.file + '.' + this.$route.params.ext;
    next();
  },
};
</script>
<style>
.picture-component {
  background: black;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
}
button.picture-close {
  background-color: black;
  width: 50px;
  height: 50px;
  border: none;
  position: fixed;
  opacity: 0.5;
  right: 10px;
  top: 10px;
}

button.picture-close:hover {
  opacity: 0.9;
}
button.picture-close span {
  color: white;
  font-size: 2rem;
}
.picture {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.picture img {
  max-width: 100%;
  object-fit: contain;
}
</style>