<template>
  <div class="container mt-4">
    <h1>{{ building.title }} {{building.notExists ? $t("notExists"):""}}</h1>

    <div class="row">
      <div
        class="col-lg-6 mt-4"
        v-if="building.images && building.images.length"
      >
        <Carousel :building="building.slug" :pictures="building.images" />
      </div>
      <div class="col-lg-6 mt-4">
        <div class="building-details">
          <p v-if="building.name">
            <span> {{ $t("name") + ": " }}</span>
            <span class="font-weight-bold"> {{ building.name }}</span>
          </p>
          <p>
            <span> {{ $t("historicAddresses") + ": " }}</span>
            <br
              v-if="
                building.historicAddresses &&
                getHistoricAddressAsArray(building.historicAddresses).length
              "
            />
            <span v-for="(address,index) in getHistoricAddressAsArray(building.historicAddresses)" class="font-weight-bold historic-address" :key="index">
              {{address }}</span
            >
          </p>
          <p>
            <span> {{ $t("constructionYear") + ": " }}</span>
            <span class="font-weight-bold">
              {{ building.constructionYear }}</span
            >
          </p>
          <p v-if="building.architectSlug">
            <span> {{ $t("architect") + ": " }}</span>
            <span class="font-weight-bold">
              <router-link
                :to="{
                  name: 'Person',
                  params: { personId: building.architectSlug },
                }"
                class="search-result"
              >
                {{ building.architectName }}</router-link
              >
            </span>
          </p>
          <p v-else>
            <span> {{ $t("architect") + ": " }}</span>
            <span class="font-weight-bold"> {{ building.architectName }}</span>
          </p>
          <p v-if="building.builderSlug">
            <span> {{ $t("investor") + ": " }}</span>
            <span class="font-weight-bold">
              <router-link
                :to="{
                  name: 'Person',
                  params: { personId: building.builderSlug },
                }"
                class="search-result"
              >
                {{ building.builderName }}</router-link
              >
            </span>
          </p>
          <p v-else>
            <span> {{ $t("investor") + ": " }}</span>
            <span class="font-weight-bold"> {{ building.builderName }}</span>
          </p>

          <p v-if="building.libraryLink">
            <img id="full-document-image" src="/images/main/fulldocument.png" class="mr-3">
            <span class="font-weight-bold"
              ><a
                :href="building.libraryLink"
                class="search-result"
                target="_blank"
                >{{ $t("fullObjectDocumetation") }}</a
              ></span
            >
          </p>
        </div>
      </div>
    </div>
    <!-- Owners -->
    <div
      class="building-section mt-5"
      v-if="shouldBeVisible(building.ownersPL, building.ownersDE)"
    >
      <h3 class="building-section-header">{{ $t("owners") }}</h3>
      <div
        class="building-text"
        v-html="building.ownersPL"
        v-if="$lang() == 'pl'"
      ></div>
      <div
        class="building-text"
        v-html="building.ownersDE"
        v-if="$lang() == 'de'"
      ></div>
    </div>
    <!-- Description -->
    <div
      class="building-section mt-5"
      v-if="shouldBeVisible(building.contentPL, building.contentDE)"
    >
      <h3 class="building-section-header">{{ $t("description") }}</h3>
      <div
        class="building-text"
        v-html="building.contentPL"
        v-if="$lang() == 'pl'"
      ></div>
      <div
        class="building-text"
        v-html="building.contentDE"
        v-if="$lang() == 'de'"
      ></div>
    </div>
    <!-- Additional information -->
    <h3
      class="building-section-header mt-5"
      v-if="additionalInformationShouldBeVisible()"
    >
      {{ $t("additionalInformation") }}
    </h3>
    <!-- Earlier or later buildings -->
    <div
      class="building-section mt-1"
      v-if="
        shouldBeVisible(
          building.earlierLaterBuildingsPL,
          building.earlierLaterBuildingsDE
        )
      "
    >
      <h4 class="building-section-header">{{ $t("earlierLaterBuildings") }}</h4>
      <div
        class="building-text"
        v-html="building.earlierLaterBuildingsPL"
        v-if="$lang() == 'pl'"
      ></div>
      <div
        class="building-text"
        v-html="building.earlierLaterBuildingsDE"
        v-if="$lang() == 'de'"
      ></div>
    </div>
    <!-- Reconstructions -->
    <div
      class="building-section mt-1"
      v-if="
        shouldBeVisible(building.reconstructionsPL, building.reconstructionsDE)
      "
    >
      <h4 class="building-section-header">{{ $t("reconstructions") }}</h4>
      <div
        class="building-text"
        v-html="building.reconstructionsPL"
        v-if="$lang() == 'pl'"
      ></div>
      <div
        class="building-text"
        v-html="building.reconstructionsDE"
        v-if="$lang() == 'de'"
      ></div>
    </div>
    <!-- Shops -->
    <div
      class="building-section mt-1"
      v-if="shouldBeVisible(building.shopsPL, building.shopsDE)"
    >
      <h4 class="building-section-header">{{ $t("shops") }}</h4>
      <div
        class="building-text"
        v-html="building.shopsPL"
        v-if="$lang() == 'pl'"
      ></div>
      <div
        class="building-text"
        v-html="building.shopsDE"
        v-if="$lang() == 'de'"
      ></div>
    </div>
    <!-- Citizens -->
    <div
      class="building-section mt-1"
      v-if="shouldBeVisible(building.citizensPL, building.citizensDE)"
    >
      <h4 class="building-section-header">{{ $t("citizens") }}</h4>
      <div
        class="building-text"
        v-html="building.citizensPL"
        v-if="$lang() == 'pl'"
      ></div>
      <div
        class="building-text"
        v-html="building.citizensDE"
        v-if="$lang() == 'de'"
      ></div>
    </div>

    <!-- Archives -->
    <div
      class="building-section mt-5"
      v-if="shouldBeVisible(building.archivesPL, building.archivesPL)"
    >
      <h3 class="building-section-header">{{ $t("archives") }}</h3>
      <div
        class="building-text"
        v-html="building.archivesPL"
        v-if="$lang() == 'pl'"
      ></div>
      <div
        class="building-text"
        v-html="building.archivesPL"
        v-if="$lang() == 'de'"
      ></div>
    </div>
  </div>
</template>

<script>
import Carousel from "./Carousel";
export default {
  name: "Building",
  components: {
    Carousel,
  },
  data() {
    return {
      building: {},
    };
  },
  created() {
    this.$api.getBuilding(this.$route.params.buildingId).then((data) => {
      this.building = data;
    });
  },
  methods: {
    shouldBeVisible: function (textPL, textDE) {
      if (this.$lang() == "pl" && !textPL) {
        return false;
      }
      if (this.$lang() == "de" && !textDE) {
        return false;
      }
      return true;
    },
    additionalInformationShouldBeVisible: function () {
      let building = this.building;
      let result =
        this.shouldBeVisible(
          building.earlierLaterBuildingsPL,
          building.earlierLaterBuildingsDE
        ) ||
        this.shouldBeVisible(
          building.reconstructionsPL,
          building.reconstructionsDE
        ) ||
        this.shouldBeVisible(building.shopsPL, building.shopsDE) ||
        this.shouldBeVisible(building.citizensPL, building.citizensDE);
      return result;
    },
    getHistoricAddressAsArray(text) {
      if (text) {
        var historicAddresses = text.split(";");
        historicAddresses = historicAddresses.filter((x) => x.length > 0);
        return historicAddresses;
      } else {
        return [];
      }
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.$api.getBuilding(this.$route.params.buildingId).then((data) => {
      this.building = data;
      next();
    });
  },
};
</script>
<style >
.search-result {
  color: var(--primary-color);
}
.search-result:hover {
  color: black;
}
h4.building-section-header {
  font-style: italic;
}
.historic-address{
  display: block;
}
#full-document-image{
  height: 30px;
}
/* .arch-carousel-picture{
  width: auto;
  min-height: 100%;
  /* object-fit: contain; */

/* .carousel{
  display: flex;
  max-height: 600px;
  max-width: 600px;
  background-color: white;
   
  vertical-align: middle;

  
}
.carousel-item img {
  display: block;

  width: auto;
  height: auto;

} */
</style>