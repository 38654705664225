<template>
  <div class="container people-component mt-4">
    <h1 class="mb-3">{{$t('people')}}</h1>
    <div class="row">
      <div class="col-md-6 col-lg-4" v-for="person in people" :key="person.id" @click="navigate(person.slug)">
        <div class="card">
          <div class="card-body">
            <h2>{{ person.lastName + " " + person.name }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "People",
  data() {
    return {
      people: [],
    };
  },
  created() {
    this.$api.getPeople().then((data) => {
      this.people = data;
    });
  },
  methods: {
    navigate: function (personId) {
      this.$router.push({ name: "Person", params: { personId}  });
    },
  },
};
</script>

<style>

.people-component .card {
  margin-bottom: 20px;
}
.people-component h2 {
  text-align: center;
}
</style>