<template>
  <div id="building-carousel" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div
        v-for="(picture, index) in pictures"
        :key="index"
        class="carousel-item"
        v-bind:class="{ active: index === 0 }"
      >
        <div class="arch-carousel">
          <div class="arch-image-wrapper">
            <img
              v-if="$lang() == 'pl'"
              :src="getPath(picture.name)"
              :data-vue-img-title="picture.descriptionPL"
              v-img:group
              class="arch-carousel-picture"
              loading="lazy"
            />
            <img
              v-if="$lang() == 'de'"
              :src="getPath(picture.name)"
              :data-vue-img-title="picture.descriptionDE"
              v-img:group
              class="arch-carousel-picture"
              loading="lazy"
            />
          </div>
          <!-- <div><p>{{picture.description}}</p></div> -->
        </div>
        <div class="arch-carousel-caption">
          <span class="left" v-if="$lang() == 'pl'">{{
            picture.descriptionPL
          }}</span>
          <span class="left" v-if="$lang() == 'de'">{{
            picture.descriptionDE
          }}</span>
          <span class="right">{{ ++index + "/" + pictures.length }}</span>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#building-carousel"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#building-carousel"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>
<script>
export default {
  props: ["pictures", "building"],
  data() {
    return {
      path: "",
    };
  },
  methods: {
    navigate: function (picture) {
      let slug = this.building;
      let fileArr = picture.name.split(".");
      let file = fileArr[0];
      let ext = fileArr[1];
      let data = picture.imageData;
      this.$router.push({ name: "Picture", params: { slug, file, ext, data } });
    },
    getPath: function (file) {
      return this.path + file;
    },
  },
  created() {
    //Building and pictures should be props from Building
    // this.building = "Mickiewicza11";
    // this.pictures = ["1.jpg", "2.jpg", "3.jpg", "4.jpg"];
    this.path = this.$settings.galleryPath;
  },
};
</script>
<style >
.arch-carousel {
  background: #343a40;
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  padding-top: 10px;
  min-height: 500px;
  max-height: 500px;
  border: solid 1px;
}

.arch-image-wrapper {
  max-height: 450px;
}
.arch-image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  cursor: pointer;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  border: solid 1px black 0.7;
  background-color: white;
  opacity: 0.8;
  height: 50px;
  width: 40px;
  outline: black;
  font-size: 32px;
  text-align: center;
  vertical-align: middle;
  color: black;
}
.carousel-control-next-icon:after {
  content: ">";
}

.carousel-control-prev-icon:after {
  content: "<";
}

.arch-carousel-caption {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}
</style>