<template>
  <div class="list-container">
    <div class="container-fluid mt-4">
      <h1 class="mb-4 font">{{ this.$t("education") }}</h1>
      <div class="row">
        <div
          class="col-md-6 col-lg-4"
          v-for="material in materials"
          :key="material.id"
        >
          <EducationCard v-bind:material="material" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EducationCard from "./EducationCard";

export default {
  name: "List",
  components: {
    EducationCard,
  },
  data() {
    return {
      materials: [],
    };
  },
  created() {
    this.$api.getEducationMaterials().then((data) => (this.materials = data));
  },
};
</script>
<style >
</style>