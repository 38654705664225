var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mt-4"},[_c('h1',[_vm._v(_vm._s(_vm.building.title)+" "+_vm._s(_vm.building.notExists ? _vm.$t("notExists"):""))]),_c('div',{staticClass:"row"},[(_vm.building.images && _vm.building.images.length)?_c('div',{staticClass:"col-lg-6 mt-4"},[_c('Carousel',{attrs:{"building":_vm.building.slug,"pictures":_vm.building.images}})],1):_vm._e(),_c('div',{staticClass:"col-lg-6 mt-4"},[_c('div',{staticClass:"building-details"},[(_vm.building.name)?_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("name") + ": "))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.building.name))])]):_vm._e(),_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("historicAddresses") + ": "))]),(
              _vm.building.historicAddresses &&
              _vm.getHistoricAddressAsArray(_vm.building.historicAddresses).length
            )?_c('br'):_vm._e(),_vm._l((_vm.getHistoricAddressAsArray(_vm.building.historicAddresses)),function(address,index){return _c('span',{key:index,staticClass:"font-weight-bold historic-address"},[_vm._v(" "+_vm._s(address))])})],2),_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("constructionYear") + ": "))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.building.constructionYear))])]),(_vm.building.architectSlug)?_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("architect") + ": "))]),_c('span',{staticClass:"font-weight-bold"},[_c('router-link',{staticClass:"search-result",attrs:{"to":{
                name: 'Person',
                params: { personId: _vm.building.architectSlug },
              }}},[_vm._v(" "+_vm._s(_vm.building.architectName))])],1)]):_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("architect") + ": "))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.building.architectName))])]),(_vm.building.builderSlug)?_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("investor") + ": "))]),_c('span',{staticClass:"font-weight-bold"},[_c('router-link',{staticClass:"search-result",attrs:{"to":{
                name: 'Person',
                params: { personId: _vm.building.builderSlug },
              }}},[_vm._v(" "+_vm._s(_vm.building.builderName))])],1)]):_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("investor") + ": "))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.building.builderName))])]),(_vm.building.libraryLink)?_c('p',[_c('img',{staticClass:"mr-3",attrs:{"id":"full-document-image","src":"/images/main/fulldocument.png"}}),_c('span',{staticClass:"font-weight-bold"},[_c('a',{staticClass:"search-result",attrs:{"href":_vm.building.libraryLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("fullObjectDocumetation")))])])]):_vm._e()])])]),(_vm.shouldBeVisible(_vm.building.ownersPL, _vm.building.ownersDE))?_c('div',{staticClass:"building-section mt-5"},[_c('h3',{staticClass:"building-section-header"},[_vm._v(_vm._s(_vm.$t("owners")))]),(_vm.$lang() == 'pl')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.ownersPL)}}):_vm._e(),(_vm.$lang() == 'de')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.ownersDE)}}):_vm._e()]):_vm._e(),(_vm.shouldBeVisible(_vm.building.contentPL, _vm.building.contentDE))?_c('div',{staticClass:"building-section mt-5"},[_c('h3',{staticClass:"building-section-header"},[_vm._v(_vm._s(_vm.$t("description")))]),(_vm.$lang() == 'pl')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.contentPL)}}):_vm._e(),(_vm.$lang() == 'de')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.contentDE)}}):_vm._e()]):_vm._e(),(_vm.additionalInformationShouldBeVisible())?_c('h3',{staticClass:"building-section-header mt-5"},[_vm._v(" "+_vm._s(_vm.$t("additionalInformation"))+" ")]):_vm._e(),(
      _vm.shouldBeVisible(
        _vm.building.earlierLaterBuildingsPL,
        _vm.building.earlierLaterBuildingsDE
      )
    )?_c('div',{staticClass:"building-section mt-1"},[_c('h4',{staticClass:"building-section-header"},[_vm._v(_vm._s(_vm.$t("earlierLaterBuildings")))]),(_vm.$lang() == 'pl')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.earlierLaterBuildingsPL)}}):_vm._e(),(_vm.$lang() == 'de')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.earlierLaterBuildingsDE)}}):_vm._e()]):_vm._e(),(
      _vm.shouldBeVisible(_vm.building.reconstructionsPL, _vm.building.reconstructionsDE)
    )?_c('div',{staticClass:"building-section mt-1"},[_c('h4',{staticClass:"building-section-header"},[_vm._v(_vm._s(_vm.$t("reconstructions")))]),(_vm.$lang() == 'pl')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.reconstructionsPL)}}):_vm._e(),(_vm.$lang() == 'de')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.reconstructionsDE)}}):_vm._e()]):_vm._e(),(_vm.shouldBeVisible(_vm.building.shopsPL, _vm.building.shopsDE))?_c('div',{staticClass:"building-section mt-1"},[_c('h4',{staticClass:"building-section-header"},[_vm._v(_vm._s(_vm.$t("shops")))]),(_vm.$lang() == 'pl')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.shopsPL)}}):_vm._e(),(_vm.$lang() == 'de')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.shopsDE)}}):_vm._e()]):_vm._e(),(_vm.shouldBeVisible(_vm.building.citizensPL, _vm.building.citizensDE))?_c('div',{staticClass:"building-section mt-1"},[_c('h4',{staticClass:"building-section-header"},[_vm._v(_vm._s(_vm.$t("citizens")))]),(_vm.$lang() == 'pl')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.citizensPL)}}):_vm._e(),(_vm.$lang() == 'de')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.citizensDE)}}):_vm._e()]):_vm._e(),(_vm.shouldBeVisible(_vm.building.archivesPL, _vm.building.archivesPL))?_c('div',{staticClass:"building-section mt-5"},[_c('h3',{staticClass:"building-section-header"},[_vm._v(_vm._s(_vm.$t("archives")))]),(_vm.$lang() == 'pl')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.archivesPL)}}):_vm._e(),(_vm.$lang() == 'de')?_c('div',{staticClass:"building-text",domProps:{"innerHTML":_vm._s(_vm.building.archivesPL)}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }