<template>
  <div class="education-card-component">
    <div class="card">
      <a :href="getDownLoadPath(material.downloadFile)" download>
      <img
        class="card-img-top"
        :src="getImagePath(material.image)"
        alt="Card image cap"
      />
      <div class="card-body">
        <h1 class="card-title">{{ material.title }}</h1>
        <p class="card-text">
          <span> {{ material.summary }}</span>
        </p>
      </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "EducationCard",
  props: ["material"],
  data() {
    return {
      imagePath: "",
      downloadPath:""
    };
  },
  created() {
    this.imagePath = this.$settings.galleryPath;
    this.downloadPath = this.$settings.downloadPath;
  },
  methods: {
    getImagePath: function (file) {
      return this.imagePath + file;
    },
    getDownLoadPath: function (file) {
      return this.downloadPath + file;
    },
    download(file){
      console.log(this.material);
      window.open(file);
    }
  },
};
</script>
<style >
.education-card-component {
  margin-bottom: 30px;
}
.card:hover {
  /* opacity: 0.7; */
  cursor: pointer;
  border: 1px solid;
  background-color: var(--primary-color);
  color: white;
}
.card:hover img {
  opacity: 0.8;
}
.card-component p {
  margin-block-end: 0em;
}
.card a{
  color: inherit;
  text-decoration: none;
}
</style>