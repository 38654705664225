<template>
  <div class="card-component">
    <div class="card" v-on:click="navigate(building.slug)">
      <img
        class="card-img-top"
        :src="getPath(building.cardImage)"
        alt="Card image cap"
      />
      <div class="card-body">
        <h1 class="card-title">{{ building.title }}</h1>
        <p class="card-text">
          <span> {{ $t("constructionYear") + ": " }}</span>
          <span class="font-weight-bold"> {{ building.constructionYear }}</span>
        </p>
        <p class="card-text">
          <span> {{ $t("architect") + ": " }}</span>
          <span class="font-weight-bold">{{
            building.architectName || building.architectId
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: ['building'],
  data() {
    return {
      imagePath:""
    };
  },
  created() {
    this.imagePath = this.$settings.galleryPath;
  },
  methods: {
    navigate: function (buildingId) {
      this.$router.push({ name: "Building" , params: { buildingId}});
    },
    getPath: function (file) {
      return this.imagePath + file;
    }
  },
};
</script>
<style >
.card-component {
  margin-bottom: 30px;
}
.card:hover {
  /* opacity: 0.7; */
  cursor: pointer;
  border: 1px solid;
  background-color: var(--primary-color);
  color: white;
}
.card:hover img {
  opacity: 0.8;
}
.card-component p {
  margin-block-end: 0em;
}
</style>