<template>
  <div class="container mt-4">
    <h2 v-if="person.name">{{ person.name + " " + person.lastName }}</h2>
    <div v-html="person.contentPL" v-if="$lang() == 'pl'"></div>
    <div v-html="person.contentDE" v-if="$lang() == 'de'"></div>
  </div>
</template>
<script>
export default {
  name: "Person",
  data() {
    return {
      person: {},
    };
  },
  created() {
    this.$api.getPerson(this.$route.params.personId).then((data) => {
      this.person = data;
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.$api.getPerson(this.$route.params.personId).then((data) => {
      this.person = data;
      next();
    });
  },
};
</script>
<style >
a {
	word-wrap: break-word;
}
</style>