<template>
  <div class="container search-container">
    <h2>{{$t('searchResult')}}</h2>
      <div v-for="(item, index) in result" :key="index" class="result">
        <router-link v-if="item.type==='Person'" :to="{ name: item.type,  params: { personId: item.slug }}" class="search-result">{{ item.title }}</router-link>
        <router-link v-if="item.type==='Building'" :to="{ name: item.type,  params: { buildingId: item.slug }}" class="search-result">{{ item.title }}</router-link>
      </div>
    </div>

</template>

<script>
export default {
  name: "SearchResult",
  data() {
    return {
      result: [],
    };
  },
  created() {
    console.log(this.$router.params);
    this.$api.search(this.$route.params.query).then((data) => {
      this.result = data;
      console.log(data);
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath == from.fullPath) {
      return;
    }
    this.$api.search(to.params.query).then((data) => {
      this.result = data;
      console.log(data);
      next();
    });
  },
  methods: {},
};
</script>
<style>
.search-container {
  margin-top: 30px;
}
.search-container h2{
  margin-bottom: 20px;;
}
.result{
  margin-bottom: 1px;

}
.search-result{
  color: var(--primary-color);
}
.search-result:hover{
  color: black;
}
</style>