<template>
  <div class="list-container">
    <div class="container-fluid mt-4">
      <div class="row">
        <div
          class="col-md-6 col-lg-4"
          v-for="building in buildings"
          :key="building.id"
        >
          <Card v-bind:building="building" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Card";

export default {
  name: "List",
  components: {
    Card,
  },
  data() {
    return {
      buildings: [],
    };
  },
  created() {
    this.$api.getBuildings().then((data) => (this.buildings = data));
  },
};
</script>
<style >
</style>