<template>
  <div
    class="container bibliography-component mt-4"
   
  >
    <h1 class="mb-2 font">{{ this.$t("bibliography") }}</h1>


    <div v-for="item in bibliography" :key="item.id" class="bibliography-item" v-html="item.content">
     
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Bibliography",
  data() {
    return {
      separator: ", ",
      bibliography: [],
    };
  },
  created() {
    console.log(this.bibliography);
    this.$api.getBibliography().then((data) => (this.bibliography = data));
  },
};
</script>

<style>
.bibliography-component div:last-child {
  margin-bottom: 100px;
}
.bibliography-item {
  margin-bottom: 20px;
}
</style>